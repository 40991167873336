<header
  class="d-flex flex-column align-items-center {{
    headerClasses['backgroundClass']
  }} {{ headerClasses['sectionClass'] }}"
>
  <div
    class="header-top d-flex justify-content-center flex-fill"
    *ngIf="
      activeUser === 1 &&
      (headerInfo['headerType'] == 'inline' ||
        headerInfo['headerType'] == 'full')
    "
  >
    <div class="navbar p-0">
      <div class="navbar-brand-wrapper d-flex">
        <a [routerLink]="['/']" class="navbar-brand pl-3">
          <!-- [ngClass]="{'pl-3': headerInfo['hideMainNav']}" -->
          <img class="finos-logo-img" src="assets/images/meshId_logo.svg" />
        </a>
        <div
          *ngIf="!headerInfo['hideMainNav']"
          class="navbar-toggler d-flex align-self-center"
          (click)="toggleMenu()"
        >
          {{ headerInfo["headerTitle"] }}
        </div>
      </div>
      <div
        class="nav flex-column collapsed"
        *ngIf="headerInfo['hideMainNav'] !== true"
      >
        <!-- <div class="separator"></div> -->
        <a
          [routerLink]="['/']"
          (mouseover)="OnHoverPrimary($event)"
          (mouseout)="OnHoverOutPrimary($event)"
          [ngClass]="{
            'position-relative nav-link': true,
            active: headerInfo['activeNavLink'] === 'integrityCheck'
          }"
          *ngIf="
            availableServices &&
            availableServices.includes('access:integrity') &&
            headerInfo['headerTitle'] !== 'Integrity Check'
          "
          >Integrity Check</a
        >
        <a
          [routerLink]="['/id-verification/dashboard']"
          (click)="noIdVerificationYet($event)"
          (mouseover)="OnHoverPrimary($event)"
          (mouseout)="OnHoverOutPrimary($event)"
          [ngClass]="{
            'position-relative nav-link': true,
            active: headerInfo['activeNavLink'] === 'idVerification'
          }"
          *ngIf="
            availableServices &&
            (availableServices.includes('access:verification') ||
              availableServices.includes('access:addressverification')) &&
            headerInfo['headerTitle'] !== 'Verify'
          "
          >Verify</a
        >
        <a
          [routerLink]="['/funds/dashboard']"
          (click)="noIdVerificationYet($event)"
          (mouseover)="OnHoverPrimary($event)"
          (mouseout)="OnHoverOutPrimary($event)"
          [ngClass]="{
            'position-relative nav-link': true,
            active: headerInfo['activeNavLink'] === 'funds'
          }"
          *ngIf="
            availableServices &&
            availableServices.includes('access:funds') &&
            headerInfo['headerTitle'] !== 'Onboard'
          "
          >Onboard</a
        >
        <a
          [routerLink]="['/form-builder']"
          (click)="noIdVerificationYet($event)"
          (mouseover)="OnHoverPrimary($event)"
          (mouseout)="OnHoverOutPrimary($event)"
          [ngClass]="{
            'position-relative nav-link': true,
            active: headerInfo['activeNavLink'] === 'form-builder'
          }"
          *ngIf="
            availableServices &&
            availableServices.includes('write:contract-authoring') &&
            headerInfo['headerTitle'] !== 'Contract Templates'
          "
          >Smart Forms</a
        >
        <a
          [routerLink]="['/templates']"
          (mouseover)="OnHoverPrimary($event)"
          (mouseout)="OnHoverOutPrimary($event)"
          [ngClass]="{
            'position-relative nav-link': true,
            active: headerInfo['activeNavLink'] === 'form-builder'
          }"
          *ngIf="
            availableServices &&
            availableServices.includes('access:kyc-template-management') &&
            headerInfo['headerTitle'] !== 'KYC Templates'
          "
          >KYC Templates</a
        >
        <a
          [routerLink]="['/questionnaires-builder']"
          (mouseover)="OnHoverPrimary($event)"
          (mouseout)="OnHoverOutPrimary($event)"
          [ngClass]="{
            'position-relative nav-link': true,
            active: headerInfo['activeNavLink'] === 'questionnaires-builder'
          }"
          *ngIf="
            availableServices &&
            availableServices.includes('access:risk-manager') &&
            headerInfo['headerTitle'] !== 'Risk Questionnaires'
          "
          >Risk Questionnaires</a
        >
        <a
        [routerLink]="['/risk-assessment/dashboard']"
        (mouseover)="OnHoverPrimary($event)"
        (mouseout)="OnHoverOutPrimary($event)"
        [ngClass]="{
          'position-relative nav-link': true,
          active: headerInfo['activeNavLink'] === 'risk-assessment'
        }"
        *ngIf="
          availableServices &&
          headerInfo['headerTitle'] !== 'Risk Assessment' &&
          fundOrganizationDetails && fundOrganizationDetails.EnableRiskAssessment
        "
        >Risk Assessments</a
      >

        <!-- <hr /> -->
      </div>
    </div>
    <!-- right hand menu -->
    <div
      class="menu-container position-absolute d-flex align-self-center"
      style="top: 27px; right: 44px"
    >
      <div *ngIf="isInvestor" class="notification-wrapper">
        <a
          href="#"
          class="underline-wrapper"
          (mouseover)="OnHoverSecondary($event)"
          (mouseout)="OnHoverOutSecondary($event)"
          (click)="OnDisplayNotifications($event)"
        >
          <img
            class="profile-icon"
            src="/assets/images/globe_placeholder.svg"
            width="28px"
            height="34px"
          />
          <span
            style="font-size: 14px; font-family: PT Sans; padding-left: 10px"
            >{{ "translation.Language" | translate }}</span
          >
        </a>
        <div class="all-notifications-outer">
          <div class="all-notifications-container">
            <div class="notification-pointer"></div>
            <div class="notifications">
              <div class="notification-container p-0">
                <div class="menu-item-text d-flex flex-wrap p-3">
                  <a
                    href="#"
                    (click)="changeLanguage($event, 'en')"
                    class="w-100 p-0 justify-content-center"
                    >{{ "translation.en" | translate }}</a
                  >
                </div>
                <hr class="menu-item-divider my-0 w-100" />
                <div class="menu-item-text d-flex flex-wrap p-3">
                  <a
                    href="#"
                    (click)="changeLanguage($event, 'nl')"
                    class="w-100 p-0 justify-content-center"
                    >{{ "translation.nl" | translate }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SUPPORT LINK -->
      <a
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 10px;
        "
        (mouseover)="OnHoverSecondary($event)"
        (mouseout)="OnHoverOutSecondary($event)"
        target="_blank"
        class="underline-wrapper"
        href="https://meshid.com/support"
      >
        <img
          class="profile-icon"
          src="/assets/images/support-agent.svg"
          width="38px"
          height="37px"
        />
        <span
          style="
            font-size: 14px;
            font-family: &quot;PT Sans&quot;;
            padding-left: 5px;
          "
          >{{ "appSpecific.GetSupport" | translate }}</span
        >
      </a>
      <!-- NOTIFICATIONS -->
      <!-- <div class="notification-wrapper" *ngIf="notifications.all.length > 0 && headerInfo['activeNavLink'] === 'integrityCheck' "> -->
      <div class="notification-wrapper" *ngIf="notifications.all.length > 0">
        <div
          class="new-notifications-number"
          *ngIf="notifications.new.length > 0"
        >
          {{ notifications.new.length }}
        </div>
        <a
          class="underline-wrapper menu-icon-link"
          (mouseover)="OnHoverSecondary($event)"
          (mouseout)="OnHoverOutSecondary($event)"
          href="#"
          (click)="OnDisplayNotifications($event)"
        >
          <img
            class="notifications-icon"
            src="assets/images/ico-bell-green.svg"
            width="29px"
            height="33px"
          />
          <!-- <span>Notifications</span> -->
        </a>
        <div class="all-notifications-outer">
          <div class="all-notifications-container">
            <div class="notification-pointer"></div>
            <div class="notifications">
              <div
                class="notification-container {{ notification.record.status }}"
                *ngFor="
                  let notification of notifications.all
                    | slice: 0 : displayNotifications
                "
              >
                <div class="notification-date">
                  {{ notification.record.createOn | date: "dd.MM.yyyy" }}
                </div>
                <!-- <div class = "notification-title">
                            A flag has been raised for a company you searched for in the past
                        </div> -->
                <div class="notification-text">
                  {{
                    notification.record.context
                      ? notification.record.context
                      : notification.record.content
                  }}
                </div>
                <div
                  class="notification-reopen-link d-flex justify-content-between align-items-center pt-1"
                  *ngIf="notification.record.status === 'open'"
                >
                  <!-- <a href="#" (click)="OnReOpenIntegrityCheck($event)" [attr.key]="notification.record.integrityCheckKey" [attr.kvk]="notification.record.company.kvkNumber" [attr.notificationkey]="notification.key">Go to integrity check</a>
                            <a href="#" (click)="OnMarkNotificationAsClosed($event)" [attr.key]="notification.key" [attr.kvk]="notification.record.company.kvkNumber" title="Don't show this notification" *ngIf="false"><img src="/assets/images/ico-delete.svg" width="16px" height="16px"/></a>
                            <div class="my-spinner w-100 text-center d-none"><img src="/assets/images/spinner-gray.svg" width="26px" height="26px"></div> -->
                </div>
                <div
                  class="notification-reopen-link d-flex justify-content-between align-items-center pt-1"
                  *ngIf="notification.record.link"
                >
                  <a
                    href="#"
                    routerLink="{{
                      parseNotificationLink(notification.record.link)
                    }}"
                    >Go to project</a
                  >
                  <!-- <a href="#" (click)="OnReOpenIntegrityCheck($event)" [attr.key]="notification.record.integrityCheckKey" [attr.kvk]="notification.record.company.kvkNumber" [attr.notificationkey]="notification.key">Go to integrity check</a>
                            <a href="#" (click)="OnMarkNotificationAsClosed($event)" [attr.key]="notification.key" [attr.kvk]="notification.record.company.kvkNumber" title="Don't show this notification" *ngIf="false"><img src="/assets/images/ico-delete.svg" width="16px" height="16px"/></a>
                            <div class="my-spinner w-100 text-center d-none"><img src="/assets/images/spinner-gray.svg" width="26px" height="26px"></div> -->
                </div>
              </div>
            </div>
            <div
              class="all-notifications"
              *ngIf="notifications.all.length > displayNotifications"
            >
              <a href="#" (click)="OnShowAllNotifications($event)"
                >All notifications</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- HANDLE NO NOTIFICATIONS -->
      <div
        class="notification-wrapper"
        *ngIf="
          notifications.all.length === 0 &&
          headerInfo['activeNavLink'] === 'integrityCheck'
        "
      >
        <a
          class="underline-wrapper menu-icon-link"
          (mouseover)="OnHoverSecondary($event)"
          (mouseout)="OnHoverOutSecondary($event)"
          href="#"
          (click)="OnDisplayNotifications($event)"
        >
          <img
            class="notifications-icon"
            src="assets/images/ico-bell-green.svg"
            width="29px"
            height="33px"
          />
          <!-- <span>Notifications</span> -->
        </a>
        <div class="all-notifications-outer">
          <div class="all-notifications-container">
            <div class="notification-pointer"></div>
            <div class="notifications">
              <div class="notification-container">
                <div class="notification-text d-flex m-heihgt-60">
                  <span
                    class="row justify-content-center align-self-center mx-auto"
                    >No Notifications</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- EOF NOTIFICATIONS -->
      <a
        href="#"
        class="underline-wrapper"
        (mouseover)="OnHoverSecondary($event)"
        (mouseout)="OnHoverOutSecondary($event)"
        (click)="
          displayFundsOrganizationAdmin &&
          headerInfo['activeNavLink'] === 'funds'
            ? onUpdateOrganizationFundsAdmin($event)
            : displayFundsOrganization &&
              headerInfo['activeNavLink'] === 'funds'
            ? onUpdateOrganizationFunds($event)
            : displayOrganisation &&
              headerInfo['activeNavLink'] === 'integrityCheck'
            ? OnUpdateProfile($event)
            : ''
        "
        *ngIf="
          (displayOrganisation ||
            displayFundsOrganization ||
            displayFundsOrganizationAdmin) &&
          (headerInfo['activeNavLink'] === 'funds' ||
            headerInfo['activeNavLink'] === 'integrityCheck')
        "
      >
        <img
          class="profile-icon"
          src="/assets/images/ico-organisation-green.svg"
          width="38px"
          height="37px"
        />
        <!-- <span>Organisation</span> -->
      </a>
      <div *ngIf="!authService.noLoginTokenAuth" class="notification-wrapper">
        <a
          href="#"
          class="underline-wrapper"
          (mouseover)="OnHoverSecondary($event)"
          (mouseout)="OnHoverOutSecondary($event)"
          (click)="OnDisplayProfileMenu($event)"
        >
          <img
            class="profile-icon"
            src="/assets/images/ico-profile-green.svg"
            width="28px"
            height="34px"
          />
          <!-- <span>Profile</span> -->
        </a>
        <div class="all-notifications-outer">
          <div class="all-notifications-container">
            <div class="notification-pointer"></div>
            <div class="notifications">
              <div class="notification-container p-0">
                <div class="menu-item-text d-flex flex-wrap p-3">
                  <div class="d-flex">
                    {{ "appSpecific.LoggedInAs" | translate }}
                  </div>
                  <div
                    class="d-flex pl-2"
                    *ngIf="
                      headerInfo['activeNavLink'] === 'integrityCheck' &&
                      loggedUser
                    "
                  >
                    {{ loggedUser["orgname"] }}
                  </div>
                  <div
                    class="d-flex pl-2"
                    *ngIf="
                      !(headerInfo['activeNavLink'] === 'integrityCheck') &&
                      loggedUser
                    "
                  >
                    {{ loggedUser["username"] }}
                  </div>
                </div>
                <hr class="menu-item-divider my-0 w-100" />
                <div class="menu-item-text d-flex justify-content-between p-3">
                  <a
                    href="#"
                    (click)="OnLogOut($event)"
                    class="logout w-100 p-0 justify-content-center"
                    >{{ "appSpecific.LogOut" | translate }}</a
                  >
                  <!-- <img src ="/assets/images/ico-logout.svg" class="mt-1" width="16px" height="16px"> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>

    <app-header-search
      [kvkResults]="kvkResults"
      [displayFlagging]="displayFlagging"
      [ngClass]="
        headerInfo['headerType'] == 'full'
          ? 'integrity-check-container d-flex flex-column flex-fill justify-content-center'
          : ''
      "
      (selectedCompany)="OnSelectedCompanyChange($event)"
      (searchTerm)="onSearchTermChanged($event)"
      (initiateFlagging)="OnFlagCompany($event)"
      *ngIf="
        activeUser > 0 &&
        (headerInfo['headerType'] == 'inline' ||
          headerInfo['headerType'] == 'full') &&
        headerInfo['activeNavLink'] === 'integrityCheck'
      "
    >
    </app-header-search>

    <app-header-project-manager
      [ngClass]="
        headerInfo['headerType'] == 'full'
          ? 'integrity-check-container d-flex flex-column flex-fill justify-content-center'
          : ''
      "
      (selectedCompany)="OnSelectedCompanyChange($event)"
      (searchTerm)="onSearchTermChanged($event)"
      (initiateFlagging)="OnFlagCompany($event)"
      (DisplayLightbox)="DisplayLightbox($event)"
      [headerInfo]="headerInfo"
      *ngIf="
        activeUser > 0 &&
        (headerInfo['headerType'] == 'inline' ||
          headerInfo['headerType'] == 'full') &&
        (headerInfo['activeNavLink'] === 'idVerification' ||
          headerInfo['activeNavLink'] === 'funds' ||
          headerInfo['activeNavLink'] === 'KYC Templates' || 
          headerInfo['activeNavLink'] === 'risk-assessment'
          )
      "
    >
    </app-header-project-manager>

    <app-contributor-invitation-header
      [ngClass]="
        headerInfo['headerType'] == 'full'
          ? 'integrity-check-container d-flex flex-column flex-fill justify-content-center'
          : ''
      "
      [projectTextColorClass]="projectTextColorClass"
      [message]="message"
      [displayLoader]="displayLoader"
      *ngIf="
        activeUser > 0 &&
        (headerInfo['headerType'] == 'inline' ||
          headerInfo['headerType'] == 'full') &&
        headerInfo['activeNavLink'] === 'idVerificationInvitation'
      "
    >
    </app-contributor-invitation-header>

    <app-share-request-header
      [ngClass]="
        headerInfo['headerType'] == 'full'
          ? 'integrity-check-container d-flex flex-column flex-fill justify-content-center'
          : ''
      "
      [projectTextColorClass]="projectTextColorClass"
      [message]="message"
      [displayLoader]="displayLoader"
      *ngIf="
        activeUser > 0 &&
        (headerInfo['headerType'] == 'inline' ||
          headerInfo['headerType'] == 'full') &&
        headerInfo['activeNavLink'] === 'requestSharing'
      "
    >
    </app-share-request-header>

    <app-form-builder-header
      (formBuilderPreview)="dispatchFormBuilderPreview($event)"
      *ngIf="
        headerInfo['headerType'] == 'inline' &&
        headerInfo['activeNavLink'] == 'form-builder'
      "
    >
    </app-form-builder-header>
  </div>
  <div
    class="header-top d-flex justify-content-center"
    *ngIf="activeUser === 0"
  >
    <a [routerLink]="['/']"
      ><img class="finos-logo-img" src="assets/images/meshId_logo.svg"
    /></a>
  </div>

  <!-- moved here -->
  <div
    class="header-top d-flex justify-content-between"
    *ngIf="headerInfo['headerType'] == 'inline_minimal'"
  >
    <div class="navbar p-0">
      <a [routerLink]="['/']" class="navbar-brand"
        ><img class="finos-logo-img" src="assets/images/meshId_logo.svg"
      /></a>
      <a
        [routerLink]="['/']"
        (mouseover)="OnHoverPrimary($event)"
        (mouseout)="OnHoverOutPrimary($event)"
        [ngClass]="{
          'position-relative nav-link': true,
          active: headerInfo['activeNavLink'] === 'integrityCheck'
        }"
        *ngIf="availableServices.includes('access:integrity')"
        >Integrity Check</a
      >
      <a
        [routerLink]="['/id-verification/dashboard']"
        (click)="noIdVerificationYet($event)"
        (mouseover)="OnHoverPrimary($event)"
        (mouseout)="OnHoverOutPrimary($event)"
        [ngClass]="{
          'position-relative nav-link': true,
          active: headerInfo['activeNavLink'] === 'idVerification'
        }"
        *ngIf="
          availableServices &&
          (availableServices.includes('access:verification') ||
            availableServices.includes('access:addressverification'))
        "
        >ID Verification</a
      >
      <hr />
    </div>
    <!--HEADER TITLE -->
    <h1 class="text-center ml-auto mr-0" *ngIf="headerInfo['headerTitle']">
      {{ headerInfo["headerTitle"] }}
    </h1>
    <div
      class="menu-container position-relative d-flex align-self-center justify-content-end"
    >
      <!-- NOTIFICATIONS -->
      <div
        class="notification-wrapper"
        *ngIf="
          notifications.all.length > 0 &&
          headerInfo['activeNavLink'] === 'integrityCheck'
        "
      >
        <div
          class="new-notifications-number"
          *ngIf="notifications.new.length > 0"
        >
          {{ notifications.new.length }}
        </div>
        <a
          class="underline-wrapper menu-icon-link"
          (mouseover)="OnHoverSecondary($event)"
          (mouseout)="OnHoverOutSecondary($event)"
          href="#"
          (click)="OnDisplayNotifications($event)"
        >
          <img
            class="notifications-icon"
            src="assets/images/ico-bell-green.svg"
            width="29px"
            height="33px"
          />
          <!-- <span>Notifications</span> -->
        </a>
        <div class="all-notifications-outer">
          <div class="all-notifications-container">
            <div class="notification-pointer"></div>
            <div class="notifications">
              <div
                class="notification-container {{ notification.Record.status }}"
                *ngFor="
                  let notification of notifications.all
                    | slice: 0 : displayNotifications
                "
              >
                <div class="notification-date">
                  {{ notification.Record.createOn | date: "dd.MM.yyyy" }}
                </div>
                <div class="notification-title">
                  A flag has been raised for a company you searched for in the
                  past
                </div>
                <div class="notification-text">
                  A service provider submitted a flag for the company
                  {{ notification.Record.company.businessName }}
                </div>
                <div
                  class="notification-reopen-link"
                  *ngIf="notification.Record.status === 'open'"
                >
                  <a
                    href="#"
                    (click)="OnReOpenIntegrityCheck($event)"
                    [attr.key]="notification.Record.integrityCheckKey"
                    [attr.kvk]="notification.Record.company.kvkNumber"
                    >Go to integrity check</a
                  >
                  <div class="my-spinner w-100 text-center d-none">
                    <img
                      src="/assets/images/spinner-gray.svg"
                      width="26px"
                      height="26px"
                    />
                  </div>
                </div>
                <div
                  class="notification-reopen-link"
                  *ngIf="notification.Record.status === 'seen'"
                >
                  <a
                    href="#"
                    (click)="OnReOpenIntegrityCheck($event)"
                    [attr.key]="notification.Record.integrityCheckKey"
                    [attr.kvk]="notification.Record.company.kvkNumber"
                    >Go to integrity check</a
                  >
                  <div class="my-spinner w-100 text-center d-none">
                    <img
                      src="/assets/images/spinner-gray.svg"
                      width="26px"
                      height="26px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="all-notifications"
              *ngIf="notifications.all.length > displayNotifications"
            >
              <a href="#" (click)="OnShowAllNotifications($event)"
                >All notifications</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- HANDLE NO NOTIFICATIONS -->
      <div
        class="notification-wrapper"
        *ngIf="
          notifications.all.length === 0 &&
          headerInfo['activeNavLink'] === 'integrityCheck'
        "
      >
        <a
          class="underline-wrapper menu-icon-link"
          (mouseover)="OnHoverSecondary($event)"
          (mouseout)="OnHoverOutSecondary($event)"
          href="#"
          (click)="OnDisplayNotifications($event)"
        >
          <img
            class="notifications-icon"
            src="assets/images/ico-bell-green.svg"
            width="29px"
            height="33px"
          />
          <!-- <span>Notificationse</span> -->
        </a>
        <div class="all-notifications-outer">
          <div class="all-notifications-container">
            <div class="notification-pointer"></div>
            <div class="notifications">
              <div class="notification-container">
                <div class="notification-text d-flex m-heihgt-60">
                  <span
                    class="row justify-content-center align-self-center mx-auto"
                    >No Notifications</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- EOF NOTIFICATIONS -->
      <a
        href="#"
        class="underline-wrapper"
        (mouseover)="OnHoverSecondary($event)"
        (mouseout)="OnHoverOutSecondary($event)"
        (click)="
          displayFundsOrganizationAdmin &&
          headerInfo['activeNavLink'] === 'funds'
            ? onUpdateOrganizationFundsAdmin($event)
            : displayFundsOrganization &&
              headerInfo['activeNavLink'] === 'funds'
            ? onUpdateOrganizationFunds($event)
            : displayOrganisation &&
              headerInfo['activeNavLink'] === 'integrityCheck'
            ? OnUpdateProfile($event)
            : ''
        "
        *ngIf="
          (displayOrganisation ||
            displayFundsOrganization ||
            displayFundsOrganizationAdmin) &&
          (headerInfo['activeNavLink'] === 'funds' ||
            headerInfo['activeNavLink'] === 'integrityCheck')
        "
      >
        <img
          class="profile-icon"
          src="/assets/images/ico-organisation-green.svg"
          width="38px"
          height="37px"
        />
        <span>Organisation</span>
      </a>
      <a
        href="#"
        class="underline-wrapper"
        (mouseover)="OnHoverSecondary($event)"
        (mouseout)="OnHoverOutSecondary($event)"
        (click)="OnUpdateProfile($event)"
      >
        <img
          class="profile-icon"
          src="/assets/images/ico-profile-grreen.svg"
          width="28px"
          height="34px"
        />
        <span>Profile</span>
      </a>
      <hr />
    </div>
  </div>

  <!-- no active user -->
  <div
    class="d-flex flex-column flex-fill justify-content-center"
    *ngIf="activeUser === 0"
  >
    <div class="unauth-message text-center mx-auto">
      We have not been able to grant you access at this time.<br />Please
      <a href="mailto:support@singletruth.io">contact support</a> to resolve
      this issue.
    </div>
  </div>

  <!-- integrity check minimal header -->
  <div
    [ngClass]="{
      'ic-ctas mx-auto': true,
      'mb-5': headerInfo['headerType'] !== 'inline_minimal'
    }"
    *ngIf="
      headerInfo['headerType'] == 'inline' &&
      headerInfo['activeNavLink'] === 'integrityCheck'
    "
  >
    <a
      class="position-relative new-flag"
      href="#"
      (click)="OnFlagCompany($event)"
      routerLinkActive="router-link-active"
      >New Flag</a
    >
    <a
      [routerLink]="['/integrity-check/history']"
      class="position-relative full-search-history"
      routerLinkActive="router-link-active"
      >See full search history</a
    >
  </div>

  <!-- global lightbox -->
  <app-lightbox
    *ngIf="displayModal"
    [formType]="formType"
    [formTitle]="formTitle"
    [formClass]="formClass"
    [formSubTitle]="formSubTitle"
    [contentDOM]="lightboxContent"
    [btnOkText]="btnOkText"
    [lightboxClass]="lightboxClass"
    [formValidator]="formValidator"
    [btnOkEnabled]="flagEntityEnabled"
    [btnCancelText]="btnCancelText"
    [displayModal]="displayModal"
    [displayLoader]="displayLoader"
    (confirmLightbox)="onLightboxClick($event)"
    (closeLightbox)="onLightboxClose($event)"
  >
  </app-lightbox>

  <!-- <div (click)="changeLanguage()">
    Change language
  </div> -->
  <app-toast-notifications></app-toast-notifications>
</header>
