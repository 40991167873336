import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class IpServiceService {
  constructor(private http: HttpClient) {}
  private googleApyKey = "AIzaSyCH3logzg7Af7tOUBznx4N_b-lHug5UoSs";

  public async getIPAddress() {
    // started to return CORS error
    // return this.http.get("https://api.ipify.org/?format=json");
    let ip = null;
    await $.getJSON(
      "https://api.ipify.org?format=jsonp&callback=?",
      function (json) {
        ip = json.ip;
      },
    );
    return ip;
  }

  public getGeoIpAddress(ipAddress: string, vaCryptoKey: string) {
    if (!ipAddress || !vaCryptoKey) {
      return null;
    }

    return this.http.get(
      `/api/proxy/geoipify/${ipAddress}`,
    );
    // return this.http.get(`https://geo.ipify.org/api/v1?apiKey=${this.geoIpifyKey}&ipAddress=${ipAddress}`);
  }

    public getNewGeoIpAddress(ipAddress: string, verificationLink: string) {
    if (!ipAddress || !verificationLink) {
      return null;
    }

    return this.http.get(
      `/api/proxy/geoipify/${ipAddress}`,
    );
    // return this.http.get(`https://geo.ipify.org/api/v1?apiKey=${this.geoIpifyKey}&ipAddress=${ipAddress}`);
  }

  public async geocodeFromAddress(address) {
    if (!address && typeof address !== "string") {
      return null;
    }

    // return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${this.googleApyKey}`).toPromise();
    const geocoder = new google.maps.Geocoder();
    return new Promise((response) =>
      geocoder.geocode({ address: address }, response),
    );
  }

  public async geocodeFromLatLng(lat, lng) {
    if (!lat || !lng) {
      return {};
    }
    const locations = { lat: lat, lng: lng };

    const geocoder = new google.maps.Geocoder();
    return new Promise((response) =>
      geocoder.geocode({ location: locations }, response),
    );
  }

  getAddrComponent(place, componentTemplate) {
    let result;
    if (!place || !place.address_components) {
      return "";
    }

    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      const addressTypeAlt = place.address_components[i].types[1];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
      if (addressTypeAlt && componentTemplate[addressTypeAlt]) {
        result = place.address_components[i][componentTemplate[addressTypeAlt]];
        return result;
      }
    }
    return;
  }

  getAddrLocation(place, format = "string") {
    const result = {};
    if (!place || !place.geometry || !place.geometry.location) {
      return "";
    }

    result["latitude"] = place.geometry.location.lat();
    result["longitude"] = place.geometry.location.lng();
    if (format === "Object") {
      return {
        lat: result["latitude"],
        lng: result["longitude"],
      };
    }
    return `${result["latitude"]},${result["longitude"]}`;
  }

  getAddress(place) {
    if (
      place.types.some((res) =>
        ["street_address", "establishment", "point_of_interest"].includes(res),
      )
    ) {
      if (this.getStreet(place)) {
        return `${this.getStreet(place)}${this.getStreetNumber(place)}`;
      } else {
        return place.address_components[0].long_name;
      }
    } else {
      return place.address_components[0].long_name;
    }
  }

  getStreetNumber(place) {
    const COMPONENT_TEMPLATE = { street_number: "short_name" };
    const streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    if (!streetNumber) {
      return "";
    } else {
      return ", " + streetNumber;
    }
  }

  getStreet(place) {
    // console.log('place', place);
    const COMPONENT_TEMPLATE = { route: "long_name" };
    const street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getCity(place) {


    let COMPONENT_TEMPLATE = { locality: "long_name" };
    let city = this.getAddrComponent(place, COMPONENT_TEMPLATE);

    if(!city) {
      let COMPONENT_TEMPLATE = { postal_town: "long_name" };
      city = this.getAddrComponent(place, COMPONENT_TEMPLATE); 
    }

    return city;
  }

  getCountry(place) {
    const COMPONENT_TEMPLATE = { country: "long_name" };
    const country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return country;
  }

  getCountryShort(place) {
    // console.log('place', place);
    const COMPONENT_TEMPLATE = { country: "short_name" };
    const country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return country;
  }

  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: "long_name" };
    const postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode ? postCode : "";
  }

  getRegion(place) {
    const COMPONENT_TEMPLATE = { sublocality: "long_name" };
    const region = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return region ? region : "";
  }

  public rad(x) {
    return (x * Math.PI) / 180;
  }

  public calculateAerialDistanceMatrix(origin, destination) {
    if (
      (!origin && typeof origin !== "string") ||
      (!destination && typeof destination !== "string")
    ) {
      return null;
    }
    const originArr = origin.split(",");
    const destinationArr = destination.split(",");
    if (originArr.length !== 2 || destinationArr.length !== 2) {
      return null;
    }

    const radius = 6378137; // Earth’s mean radius in meter
    const dLat = this.rad(destinationArr[0] - originArr[0]);
    const dLng = this.rad(destinationArr[1] - originArr[1]);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(originArr[0])) *
        Math.cos(this.rad(destinationArr[0])) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return radius * c > 1000
      ? `${((radius * c) / 1000).toFixed(0)}km`
      : `${(radius * c).toFixed(0)}m`;
  }

  public async calculateDistanceMatrix(origin, destination) {
    if (
      (!origin && typeof origin !== "string") ||
      (!destination && typeof destination !== "string")
    ) {
      return null;
    }
    const originArr = origin.split(",");
    const destinationArr = destination.split(",");
    // 41.335113,21.564158
    // 42.00263,21.413212
    // return this.http.get(`https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${this.googleApyKey}`);
    const matrix = new google.maps.DistanceMatrixService();
    return new Promise((response) =>
      matrix.getDistanceMatrix(
        {
          origins: [new google.maps.LatLng(originArr[0], originArr[1])],
          destinations: [
            new google.maps.LatLng(destinationArr[0], destinationArr[1]),
          ],
          travelMode: google.maps.TravelMode.WALKING,
        },
        response,
      ),
    );
  }

  public getStaticMapDistanceImage(
    origin: string,
    destination: string,
    zoom: number = 17,
  ) {
    if (!origin || !destination) {
      return null;
    }
    // examples:
    // origin: 41.335113,21.564158
    // destination: 42.00263,21.413212
    // return this.http.get(`https://maps.googleapis.com/maps/api/staticmap?zoom=6&size=704x556&markers=color:blue%7Clabel:S%7C62.107733,-145.541936&path=color:#1a93a5|weight:5|40.737102,-73.990318|40.749825,-73.987963|40.752946,-73.987384|40.755823,-73.986397`);

    // we will not provide zoom value, we will use the autozoom feature
    // return `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=704x542&markers=color:blue%7Clabel:A%7C${origin}&markers=color:blue%7Clabel:B%7C${destination}&path=color:%231a93a5%7Cweight:5%7C${origin}%7C${destination}&key=AIzaSyCiEbImmus_t_vbzD1hv6KgJO7D7d1V3PI`;
    return `https://maps.googleapis.com/maps/api/staticmap?&size=704x535&markers=color:%2300889c%7Clabel:A%7C${origin}&markers=color:%2300889c%7Clabel:B%7C${destination}&path=color:%23fc4242%7Cweight:5%7C${origin}%7C${destination}&key=AIzaSyCiEbImmus_t_vbzD1hv6KgJO7D7d1V3PI`;
  }

  public getStaticMapmage(
    destination: string,
    label: string = "B",
    zoom: number = 17,
  ) {
    if (!destination) {
      return null;
    }
    // examples:
    // destination: 42.00263,21.413212
    // return this.http.get(`https://maps.googleapis.com/maps/api/staticmap?zoom=6&size=704x556&markers=color:blue%7Clabel:S%7C62.107733,-145.541936&path=color:#1a93a5|weight:5|40.737102,-73.990318|40.749825,-73.987963|40.752946,-73.987384|40.755823,-73.986397`);

    // we will not provide zoom value, we will use the autozoom feature
    // return `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=704x542&markers=color:blue%7Clabel:A%7C${origin}&markers=color:blue%7Clabel:B%7C${destination}&path=color:%231a93a5%7Cweight:5%7C${origin}%7C${destination}&key=AIzaSyCiEbImmus_t_vbzD1hv6KgJO7D7d1V3PI`;
    return `https://maps.googleapis.com/maps/api/staticmap?&size=704x535&markers=color:%2300889c%7Clabel:${label}%7C${destination}&key=AIzaSyCiEbImmus_t_vbzD1hv6KgJO7D7d1V3PI`;
  }

  public getStaticMapIpImage(center: string) {
    if (!center) {
      return null;
    }
    // examples:
    // origin: 41.335113,21.564158
    // destination: 42.00263,21.413212
    // return this.http.get(`https://maps.googleapis.com/maps/api/staticmap?zoom=6&size=704x556&markers=color:blue%7Clabel:S%7C62.107733,-145.541936&path=color:#1a93a5|weight:5|40.737102,-73.990318|40.749825,-73.987963|40.752946,-73.987384|40.755823,-73.986397`);

    // we will not provide zoom value, we will use the autozoom feature
    // return `https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=370x380&maptype=hybrid&markers=color:blue%7C${center}&key=AIzaSyCiEbImmus_t_vbzD1hv6KgJO7D7d1V3PI`;
    return `https://maps.googleapis.com/maps/api/staticmap?zoom=10&size=370x380&markers=color:%2300889c%7C${center}&key=AIzaSyCiEbImmus_t_vbzD1hv6KgJO7D7d1V3PI`;
  }
}
