import {
  AfterContentChecked,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UserService } from "src/app/user.service";
import { Router } from "@angular/router";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { FormBuilderServiceService } from "./form-builder-service.service";

@Component({
  selector: "app-form-builder",
  templateUrl: "./form-builder.component.html",
  styleUrls: ["./form-builder.component.scss"],
})
export class FormBuilderComponent implements OnInit, AfterContentChecked {
  // header vars
  headerInfo = {
    headerType: "inline", // 'inline',
    headerTitle: "Smart Forms",
    activeNavLink: "form-builder",
    projectType: "FormBuilder",
  };
  headerClasses = {
    backgroundClass: "tealish-gradient-bg",
    sectionClass: "ic_height_auto", // 'ic_height_full_page',
  };
  kvkResults: any;

  // local vars
  private fileInput: ElementRef;
  private jsonElement: ElementRef;
  @ViewChild("fileInput") set fInput(content: ElementRef) {
    if (content) {
      this.fileInput = content;
    }
  }
  @ViewChild("json") set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.jsonElement = content;
      if ((this.previewJson || this.previewList) && this.jsonElement) {
        this.jsonElement.nativeElement.innerHTML = "";
        this.jsonElement.nativeElement.appendChild(
          document.createTextNode(this.stringifiedJson),
        );
      }
    }
  }
  public form: Object = {
    components: [],
  };
  preview = false;
  previewList = true;
  previewJson = false;
  formattedJson = {};
  stringifiedJson = null;
  downloadJson = null;
  selectedFile: File = null;
  displayLoader = false;
  closePeview = false;
  myOrganisationTemplates;
  availableServices;
  formType = "";
  copyFrom = null;
  displayModal = false;
  lightboxContent: any[];
  btnOkText = "Ok";
  btnCancelText = "Cancel";

  constructor(
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private idVerification: IdVerificationService,
    public formBuilderService: FormBuilderServiceService,
    private router: Router,
  ) {}

  ngOnInit() {
    // console.log('org', this.userService.getUserOrganization());
    // this.togglePreviewList();
    // // this.togglePreviewJson();
    this.form = this.formBuilderService.formattedJson;
    $("body").removeClass("app-questionnaire-builder-class");

    // await this.userService
    //     .getEndPoint('api/pdf/templates/organizations', {responseType: 'json'}, {observe: 'body'}, 'verification')
    //     .toPromise()
    //     .then(response => {
    //       console.log('parsing contract templates, but before that here is the response', response);
    //       this.idVerification.parseContractTemplates(response, 'funds');
    //       this.myOrganisationTemplates = this.idVerification.organizationContractTemplates;
    //       console.log('my org templates', this.myOrganisationTemplates);
    //     })
    //     .catch(error => console.error(error));
  }

  onRender(event) {
    console.log("event");
    alert("rendered");
  }

  ngAfterContentChecked() {
    this.availableServices = this.userService.getUserPermissions();
    if (
      this.availableServices &&
      !this.availableServices.includes("write:contract-authoring")
    ) {
      this.router.navigate(["/"]);
    }
  }

  setFormJson(formJson, setForm = false, stringified = false) {
    if (!formJson) {
      return;
    }
    if (stringified) {
      this.formattedJson = this.formBuilderService.formattedJson =
        JSON.parse(formJson);
    }

    // populate stringifiedJson
    this.stringifiedJson = this.formBuilderService.stringifiedJson =
      JSON.stringify(this.formattedJson, null, 4);

    // populate downloadJson
    this.downloadJson = this.formBuilderService.downloadJson =
      this.sanitizer.bypassSecurityTrustUrl(
        "data:text/json;charset=UTF-8," +
          encodeURIComponent(this.stringifiedJson),
      );

    if (setForm) {
      this.form = this.formBuilderService.form =
        this.formBuilderService.formattedJson;
      if (this.jsonElement) {
        this.jsonElement.nativeElement.innerHTML = "";
        this.jsonElement.nativeElement.appendChild(
          document.createTextNode(this.formBuilderService.stringifiedJson),
        );
      }
    }
  }

  onChange(event) {
    // allow JSON object to be updated only if the event type is set to updateComponent
    // this will prevent making updates to the JSON when for instance clicking a radio button in a formbuilder
    // triggers an event of type 'change'
    const allowedEvents = [
      "addComponent",
      "updateComponent",
      "deleteComponent",
      "saveComponent",
    ];
    console.log("event type", event.type);
    if (!allowedEvents.includes(event.type)) {
      return;
    }

    if (this.jsonElement) {
      this.jsonElement.nativeElement.innerHTML = "";
      this.jsonElement.nativeElement.appendChild(
        document.createTextNode(JSON.stringify(event.form, null, 4)),
      );
    }
    this.formattedJson = this.formBuilderService.formattedJson = event.form;
    this.setFormJson(event.form);
  }

  togglePreviewJson() {
    this.previewJson = !this.previewJson;
    if (this.previewJson) {
      this.previewList = !this.previewJson;
      if (this.jsonElement) {
        this.preview = !this.previewJson;
      }
    }
  }

  togglePreviewList() {
    this.previewList = !this.previewList;
    if (this.previewList) {
      this.preview = !this.previewList;
      this.previewJson = !this.previewList;
    }
  }

  formBuilderPreview($event) {
    console.log($event.method);
    if ($event.method === "form") {
      this.preview = true;
    } else {
      this.togglePreviewJson();
    }
  }

  async loadMyForm(template) {
    // GET THE TEMPLATE
    // this.http.get(this.baseUrl
    //   + 'api/pdf/templates/'
    //   + this.verificationRecord.template
    //   + '/template/'
    //   + this.verificationRecord.organizationKey.replace('ORG:', '')
    //   , {responseType: 'text'}
    // )
    // .pipe(takeUntil(this.unsubscribe$)).subscribe(async template => {
    //   try {
    //     template = JSON.parse(template);
    //     if (Array.isArray(template)) {
    //       template = template[0].data;
    //     }
    //   } catch (e) { }
    //   this.contractTemplate = template;
    //   this.previewPdf = 'data:application/pdf;base64,' + template;
    // }, error => {
    //   alert('Error while fetching the template.');
    //   this.previewFormio = false;
    //   this.errorState = true;
    // });

    // GET THE SCHEMA
    if (this.userService.getUserOrganizationId()) {
      await this.userService
        .getEndPoint(
          "api/pdf/templates/" +
            template +
            "/schema/" +
            this.userService.getUserOrganizationId(),
          { responseType: "json" },
          { observe: "body" },
          "verification",
        )
        .toPromise()
        .then((schema) => {
          this.formattedJson = this.formBuilderService.formattedJson =
            JSON.parse(schema[0].data);
          this.setFormJson(this.formattedJson, true);
          this.togglePreviewList();
        })
        .catch((error) => console.error(error));
    }
    // this.http.get(this.baseUrl
    //   + 'api/pdf/templates/'
    //   + this.verificationRecord.template
    //   + '/schema/'
    //   + this.verificationRecord.organizationKey.replace('ORG:', '')
    // )
    // .pipe(takeUntil(this.unsubscribe$)).subscribe(async schema => {
    //   // We will alter the schema to prefill any additional data the BE has sent us under personData
    //   // console.log('this is the new schema:', schema);
    //   // console.log('this is the new schema:', schema, atob(schema[0].data as any));
    //   // schema = atob(schema[0].data as any);
    //   if (this.verificationRecord.personData) {
    //     // let newSchema = JSON.parse(atob(schema[0].data as any));
    //     let newSchema = JSON.parse(schema[0].data);
    //     newSchema = this.prefillBEData(this.verificationRecord.personData, newSchema);
    //     this.formattedJson = newSchema;
    //   } else {
    //     // const convertedSchema = atob(schema[0].data as any);
    //     this.formattedJson =  JSON.parse(schema[0].data);
    //   }
    //   this.previewFormio = true;
    // }, error => {
    //   alert('Error while fetching the schema.');
    //   this.previewFormio = false;
    //   this.errorState = true;
    // });
  }

  async copyMyForm(newName) {
    // payload = {'name': projectName};
    // if (defaultTemplateKey !== undefined) {
    //   payload.defaultTemplateKey = defaultTemplateKey;
    // }
    // this.userService.postEndPoint(this.buildEndPoint('projects'), payload, {responseType: 'json'},
    //   {observe: 'body'}, this.getCCType()).subscribe(project => {
    //   if (typeof project === undefined) {
    //     console.log('There was an unexpected error while posting a new project');
    //     return;
    //   }
    //   this.prependProject(this.helperService.sanitize(project));
    // },
    // error => {
    //   console.log('The following error occured while posting a new project', error);
    // });

    // create a copy of the form
    if (this.userService.getUserOrganizationId()) {
      const payload = { newName: newName };
      await this.userService
        .postEndPoint(
          "api/pdf/templates/" +
            this.copyFrom +
            "/copy/" +
            this.userService.getUserOrganizationId(),
          payload,
          { responseType: "json" },
          { observe: "body" },
          "verification",
        )
        .toPromise()
        .then((schema) => {
          this.formattedJson = this.formBuilderService.formattedJson =
            JSON.parse(schema[0].data);
          this.setFormJson(this.formattedJson, true);
          this.togglePreviewList();
        })
        .catch((error) => console.error(error));
    }
  }

  // UI methods

  /**
   * Displays the submenu for this form
   * @param event click event
   */
  DisplayMyOptions(event) {
    event.preventDefault();
    event.stopPropagation();
    // TODO: set active form
    // this.idVerificationService.setActiveProjectSettings($(event.target).closest('.card').attr('id'));
    // $('.project-submenu').hide();
    // get the height -> take it offscreen
    const seticon =
      $(event.target).closest(".card").find(".settings-icon").offset().top -
      $(window).scrollTop();
    const submenu = $(event.target).closest(".card").find(".form-submenu");
    submenu.css("top", "-10000px").show();
    const submenu_height = submenu.height();
    submenu.hide();
    submenu.css("top", seticon - submenu_height + 20 + "px");
    submenu.fadeIn();
  }

  OnFormEdit(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    // closestCard.find('.form-submenu').fadeOut(); // close the menu
    this.loadMyForm(closestCard.find(".body").attr("data-template-key"));
    // TODO: set active form
    // this.idVerificationService.setActiveProjectSettings(closestCard.attr('id'));
    // this.displayCustomizeProjectLightbox();
    // this.loadingLocked = false;
    // this.allProjectsLoaded = false;
  }

  OnCopyFrom(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    // closestCard.find('.form-submenu').fadeOut(); // close the menu
    this.copyFrom = closestCard.find(".body").attr("data-template-key");
    // this.copyMyForm(closestCard.find('.body').attr('data-template-key'));
    this.DisplayEnterNameCopyForm(
      closestCard.find(".body").attr("data-template-name"),
    );
  }

  DisplayEnterNameCopyForm(suggestedName) {
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "copy_form",
      result_details: [
        {
          group_result_title: "enter_name_for_the_new_form", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "subtitle-alternate",
              class: "increased-margin-top mt-3 location-text",
              params: {
                content:
                  "Name the new smart form, which includes the form and the related output document",
              },
            },
            {
              type: "paragraph",
              class: "text-center mb-3",
              params: {
                content: `Please enter the name for the new form that will be based on ${suggestedName}`,
              },
            },
            {
              type: "inputText",
              params: {
                label: "New smart form name",
                id: "newFormName",
                name: "newFormName",
                content: `Copy of ${suggestedName}`,
                required: "true",
                labelClass: "font-weight-bold",
                maxLength: 60,
              },
            },
            // {
            //   'type': 'button_group',
            //   'params': [
            //     {
            //       'content': 'Ok',
            //       // 'id': 'accept',
            //       'action': 'onDeletePersonAccept',
            //       'class': 'form-button form-button-1',
            //       'display': true
            //     },
            //     {
            //       'content': 'Cancel',
            //       // 'id': 'rescan',
            //       'action': 'onDeletePersonReject',
            //       'class': 'form-button-2',
            //       'display': true // TODO: check if user may edit the information
            //     }
            //   ]
            // }
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  onLightboxConfirm($event) {
    this.displayLoader = true;
    this.copyMyForm($("#newFormName").val());
  }

  onLightboxClose($event) {
    this.copyFrom = null;
    this.displayModal = false;
  }
}
