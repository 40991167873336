import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { contains } from "jquery";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { UserService } from "src/app/user.service";
import { ElementsService } from "../elements.service";
import { UiService } from "src/app/ui/ui.service";

@Component({
  selector: "app-digital-verification-evidence-share-dialog",
  templateUrl: "./digital-verification-evidence-share-dialog.component.html",
  styleUrls: ["./digital-verification-evidence-share-dialog.component.scss"],
})
export class DigitalVerificationEvidenceShareDialogComponent implements OnInit {
  constructor(
    public elementService: ElementsService,
    private helperService: HelperServiceService,
    public translate: TranslateService,
    public idVerificationService: IdVerificationService,
    public userService: UserService,
    private UIservice: UiService,
  ) {}
  @Output() contentReturn = new EventEmitter<any>();
  @Input() element;
  entityName;
  digitalEvidenceTypes;
  confirmedEmail;
  typeOfInvestor;
  isIdentityGo = false;
  isIdentityGoRP = false;
  isContract = false;

  ngOnInit(): void {
    // we need to find the name of the entity the digital verificaitons belong too
    if (this.element && this.element.evidence) {
      let rKey = this.element.evidence.belongsToRequest;
      if (rKey) {
        this.element.params.evidenceTypes.forEach((evidenceType) => {
          if (evidenceType.investorReviewName && evidenceType.IRkey === rKey) {
            this.entityName = evidenceType.investorReviewName;
            this.confirmedEmail = evidenceType.confirmedEmail;
            this.typeOfInvestor = evidenceType.type;
          }
        });
      } else {
        rKey = this.element.evidence.relatedPartyKey;
        this.element.params.evidenceTypes.forEach((evidenceType) => {
          if (
            evidenceType.investorReviewName &&
            evidenceType.relatedPartyKey === rKey
          ) {
            this.entityName = evidenceType.investorReviewName;
            this.confirmedEmail = evidenceType.confirmedEmail;
            this.typeOfInvestor = evidenceType.type;
          }
        });
      }
    }

    this.digitalEvidenceTypes = this.element.params.digitalEvidenceTypes;
    if (
      this.element.params.singleIdentityGoStep &&
      !this.element.params.loadingFromBe
    ) {
      this.idVerificationService.getActiveInvestorDetaisl().singleIdentityGoStep =
        false;
      this.isIdentityGo = true;
      this.digitalEvidenceTypes =
        this.idVerificationService.getActiveInvestorDetaisl().identityGoStep;
    } else if (
      this.element.params.singleIdentityGoStepRelated &&
      !this.element.params.loadingFromBe
    ) {
      this.isIdentityGo = true;
      this.isIdentityGoRP = true;
      this.digitalEvidenceTypes =
        this.element.params.singleIdentityGoStepRelated;
    } else if (
      this.element.params.singleContractStep &&
      !this.element.params.loadingFromBe
    ) {
      this.idVerificationService.getActiveInvestorDetaisl().singleContractStep =
        false;
      this.isContract = true;
      this.digitalEvidenceTypes =
        this.idVerificationService.getActiveInvestorDetaisl().contractStep;
    } else {
      this.digitalEvidenceTypes = this.element.params.digitalEvidenceTypes;
    }
  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }




  parseVerificationName(backendName) {
    if (backendName === "Identity") {
      return "Identity verification";
    }
    if (backendName === "Address") {
      return "Address verification";
    }
    if (backendName === "Contract") {
      return "Smart form";
    }
    if (backendName === "APS") {
      return "Compliance check";
    }
  }
}
